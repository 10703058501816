import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LeadFormModel } from '../../models/LeadForm';
import { MenuState } from '../../models/MenuState';
import { ShowResults } from '../../models/ShowResults';
// import * as ouibounce from 'ouibounce';
import { QuoteService } from '../../services/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UTM } from 'src/app/models/Utm.model';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  public isEditable: boolean = false;
  public QuoteResults: any;
  public loading: boolean = false;
  public fullname: string = null;
  // public active_popup: boolean = false;
  public message: string = null;
  public UtmData: UTM = null;
  public QueryParams: any = null;
  public QuotesGUID: any[] = [];
  public SendQuotesForm: SendQuotesFormValues = new SendQuotesFormValues;
  public FormValid: boolean = false;
  public FormErrors: any = {
    "zip_code": false,
    "dob": false,
    "coverage_amount": false,
    "gender": false,
    "smoker": false,
    "medication": false,
    "weight": false,
    "height_feet": false,
    "height_inch": false,
    "health_conditions": false,
  };

  // title = 'ng-ouibounce';
  // @ViewChild('modal', {static: false}) modal: ElementRef;

  formSubmitted = false;
  public sendQuote = this.formBuilder.group({
    full_name: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
  });

  ngAfterViewInit() {
    // ouibounce(this.modal.nativeElement, { aggressive: true });
  }
  
  constructor(
    public FormValues: LeadFormModel = new LeadFormModel,
    public ResultShowValues: ShowResults = new ShowResults,
    public MenuStateValues: MenuState = new MenuState,
    private _QuoteService: QuoteService,
    private router: Router,
    private formBuilder: FormBuilder,
    public activateRoute: ActivatedRoute,
  ) { 
    this.QueryParams = this.activateRoute.snapshot.queryParams;
    this.SaveLeadData();
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  SaveLeadData() {
    let data = {
      //API Key
      __key: '14b0ab9f2da33030718cc1b3e628cb1b',
      activity_page: 'basic',
      // __key: this.AppGlobals.spa_id,
      full_name: this.FormValues.FirstName + " " + this.FormValues.LastName,
      email: this.FormValues.Email,
      address: this.FormValues.Address,
      city: this.FormValues.City,
      state: this.FormValues.State,
      country: this.FormValues.Country,
      phone: this.FormValues.PhoneNumber,
      dob: this.FormValues.Dob,
      gender: this.FormValues.Gender,
      face_amount: this.FormValues.CoverageAmount,
      product:this.FormValues.ProductId,
      tobacco: this.FormValues.Smoker,
      zip_code: this.FormValues.ZipCode,
      weightLbs: this.FormValues.Weight,
      heightFt: this.FormValues.HeightInFeet,
      heightIn: this.FormValues.HeightInInch,
      medications: this.FormValues.Medication,
      health_info : this.FormValues.HealthConditions,
    }

    if(this.FormValues.HealthConditions.length == 0){
      delete data.health_info;
    }

    this.loading = true;

    this._QuoteService.AddLead(data).subscribe(
      result => {
        if(result['success']) {
          let data = result['data'];
          this.FormValues.LeadId = data['lead_id'];
          let _utmData
          if(this.FormValues.utm_source!=null){
            _utmData = {
              utm_source: this.FormValues.utm_source,
              utm_campaign: this.FormValues.utm_campaign,
              utm_medium: this.FormValues.utm_medium,
              utm_content: this.FormValues.utm_content,
              utm_term: this.FormValues.utm_term,
              referred: this.FormValues.referred,
              lead_id: data['lead_id'],
              // ...this.QueryParams,
              organization_id: '5b32ddde9c779',
              entity_type: 'Final Expense SPA',
            }
          }else{
            _utmData = {
              utm_source: null,
              utm_campaign: null,
              utm_medium: null,
              utm_content: null,
              utm_term: null,
              referred: this.FormValues.referred,
              lead_id: data['lead_id'],
              // ...this.QueryParams,
              organization_id: '5b32ddde9c779',
              entity_type: 'Final Expense SPA',
            }
          }
          
          this._QuoteService.SendUtmData(_utmData);
        }
      }
    );

    this.GetQuotes();
  }

  GetQuotes() {
    this.loading = true;

    let data = {
      gender: this.FormValues.Gender,
      tobacco: this.FormValues.Smoker,
      face_amount:this.FormValues.CoverageAmount,
      dob: this.FormValues.Dob,
      zip_code: this.FormValues.ZipCode,

      // user details
      first_name: this.FormValues.FirstName,
      last_name: this.FormValues.LastName,
      email: this.FormValues.Email,
      phone_number: this.FormValues.PhoneNumber,

      //API Key
      __key: '14b0ab9f2da33030718cc1b3e628cb1b'
    };

    this._QuoteService.GetQuote(data).subscribe(
      result => {
        if(result['message'] != undefined) {
          this.message = result['message'];

        }
        else {
          this.QuoteResults = result["data"];
          // Send Request to create the lead
          this.SendRequest();
        }
        this.loading = false;
      }
    );
  }

  SendRequest() {
    
    this.formSubmitted = true;
    this.fullname=this.FormValues.FirstName+" "+this.FormValues.LastName;
    // if (this.sendQuote.valid) {
      this.loading = true;
      if(this.QuoteResults["message"] == undefined) {
        // push the values of the quotes
        let data = {
          save_quote_list: this.QuoteResults,
  
          // full_name: this.SendQuotesForm.name,
          // email: this.SendQuotesForm.email,
          // ...this.sendQuote.value,
  
          gender: this.FormValues.Gender,
          tobacco: this.FormValues.Smoker,
          face_amount:this.FormValues.CoverageAmount,
          product:this.FormValues.ProductId,
          dob: this.FormValues.Dob,
          address: this.FormValues.Address,
          city: this.FormValues.City,
          state: this.FormValues.State,
          country: this.FormValues.Country,
          zip_code: this.FormValues.ZipCode,
          weightLbs: this.FormValues.Weight,
          heightFt: this.FormValues.HeightInFeet,
          heightIn: this.FormValues.HeightInInch,
          medications: this.FormValues.Medication,
          health_info : this.FormValues.HealthConditions,

          // user details
          full_name: this.FormValues.FirstName+" "+this.FormValues.LastName,
          email: this.FormValues.Email,
          phone: this.FormValues.PhoneNumber,

          //API Key
        __key: '14b0ab9f2da33030718cc1b3e628cb1b'

        };

        // if(this.FormValues.HealthConditions.length == 0){
        //  delete data.health_info;
        // }
  
        // this._QuoteService.SendQuoteToMe(data).subscribe(
        //   result => {
        //     this.FormValues.LeadId=result['data']['lead_id'];
        //     this.FormValues.Email=result['data']['email'];
        //     this.loading = true;
        //     // this.active_popup = true;
        //     this.FormValues.ZipCode = null;
        //     this.FormValues.CoverageAmount = null;
        //     this.FormValues.Term = null;
        //     this.FormValues.Goal = null;
        //     this.FormValues.Gender  = null;
        //     this.FormValues.Smoker  = null; 
        //     this.FormValues.Medication  = null;
        //     this.FormValues.Dob  = null;
        //     this.FormValues.Citizen  = null;
        //     this.FormValues.Family  = null;
        //     this.FormValues.Weight  = null;
        //     this.FormValues.HeightInFeet  = null;
        //     this.FormValues.HeightInInch  = null;
        //     this.FormValues.HealthConditions = [];
        //     this.FormValues.GUID = null;
        //     this.sendQuote.reset();
        //     this.router.navigate(['thank-you']);
        //   }
        // );
      }
    // }
  }

  editFields() {
    this.isEditable = true;
  }

  editComplete() {
    this.FormErrors = this.validateForm();

    // check the form for error
    if(this.FormValid) {
      this.isEditable = false;
      this.GetQuotes();
    }
    else {

    }
  }

  precisionRound(number, precision) {
    let factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }
  
  apply(guid) {
    this.loading = true;

    this.FormValues.GUID = guid;

    let data = {
      // application data
      quote_guid: this.FormValues.GUID,

      // ...this.applyForm.value,

      // Values from the Single Page Form
      lead_id:this.FormValues.LeadId,
      full_name: this.FormValues.FirstName+" "+this.FormValues.LastName,
      phone: this.FormValues.PhoneNumber,
      email:this.FormValues.Email,
      gender: this.FormValues.Gender,
      tobacco: this.FormValues.Smoker,
      face_amount:this.FormValues.CoverageAmount,
      product:this.FormValues.ProductId,
      dob: this.FormValues.Dob,
      address: this.FormValues.Address,
      city: this.FormValues.City,
      state: this.FormValues.State,
      country: this.FormValues.Country,
      zip_code: this.FormValues.ZipCode,
      weightLbs: this.FormValues.Weight,
      heightFt: this.FormValues.HeightInFeet,
      heightIn: this.FormValues.HeightInInch,
      medications: this.FormValues.Medication,
      health_info : this.FormValues.HealthConditions,

      //API Key
      __key: '14b0ab9f2da33030718cc1b3e628cb1b'
    };

    if(this.FormValues.HealthConditions.length == 0){
      delete data.health_info;
    }

    this._QuoteService.AddLead(data).subscribe(
      result => {
        if(result){
          // this.active_popup = true;

          this.router.navigate(['thank-you']);
          this.FormValues.ZipCode = null;
          this.FormValues.CoverageAmount = null;
          this.FormValues.Term = null;
          this.FormValues.Goal = null;
          this.FormValues.Gender  = null;
          this.FormValues.Smoker  = null; 
          this.FormValues.Medication  = null;
          this.FormValues.Dob  = null;
          this.FormValues.Citizen  = null;
          this.FormValues.Family  = null;
          this.FormValues.Weight  = null;
          this.FormValues.HeightInFeet  = null;
          this.FormValues.HeightInInch  = null;
          this.FormValues.HealthConditions = [];
          this.FormValues.GUID = null;
          // this.applyForm.reset();
          this.formSubmitted = false;
          this.loading = false;
        }
      }
    );
  }


    // navigate to apply page
    // this.router.navigate(['apply']);

  validateForm() {
    this.FormValid = true;
    let error: any = {
      "zip_code": false,
      "dob": false,
      "coverage_amount": false,
      "gender": false,
      "smoker": false,
      "medication": false,
      "weight": false,
      "height_feet": false,
      "height_inch": false,
      "health_conditions": false,

    };

    if(this.FormValues.ZipCode == null || this.FormValues.ZipCode == "") {
      this.FormValid = false;
      error.zip_code = true;
    }
    else {
      error.zip_code = false;
    }

    if(this.FormValues.Dob == null || this.FormValues.Dob == "") {
      this.FormValid = false;
      error.dob = true;
    }
    else {
      error.dob = false;
    }

    if(this.FormValues.CoverageAmount == null || this.FormValues.CoverageAmount == "") {
      this.FormValid = false;
      error.coverage_amount = true;
    }
    else {
      error.coverage_amount = false;
    }

    if(this.FormValues.Gender == null || this.FormValues.Gender == "") {
      this.FormValid = false;
      error.gender = true;
    }
    else {
      error.gender = false;
    }

    if(this.FormValues.Smoker == null || this.FormValues.Smoker == "") {
      this.FormValid = false;
      error.smoker = true;
    }
    else {
      error.smoker = false;
    }

    if(this.FormValues.Medication == null || this.FormValues.Medication == "") {
      this.FormValid = false;
      error.medication = true;
    }
    else {
      error.medication = false;
    }

    if(this.FormValues.Weight == null || this.FormValues.Weight == "") {
      this.FormValid = false;
      error.weight = true;
    }
    else {
      error.weight = false;
    }

    if(this.FormValues.HeightInFeet == null) {
      this.FormValid = false;
      error.height_feet = true;
    }
    else {
      error.height_feet = false;
    }

    if(this.FormValues.HeightInInch == null) {
      this.FormValid = false;
      error.height_inch = true;
    }
    else {
      error.height_inch = false;
    }

    return error;
  }
}

class SendQuotesFormValues {
  public name: string;
  public email: string;
}
